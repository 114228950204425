<script lang="ts" setup>
import { withLeadingSlash } from 'ufo'
import type { UILinkComponentProps } from './UILink.props'

const props = withDefaults(defineProps<UILinkComponentProps>(), {
  outlineOffset: true,
  anatomy: 'link',
  linkUnderline: true,
  underlineEffect: 'default',
})
const localePath = useLocalePath()

const url = computed(() => {
  // Check if there is an internal link
  if (props.internalLink?.urlSlug) {
    return withLeadingSlash(props.internalLink.urlSlug)
  }
  // Check if props.target is an external URL
  if (props.target && !/^https?:\/\//.test(props.target)) {
    // If it's not an external URL, apply withLeadingSlash
    return localePath(withLeadingSlash(props.target || ''))
  }
  // If it's an external URL, return it directly
  return props.target || ''
})

const mountedSkipFocus = ref(false)
onMounted(() => {
  mountedSkipFocus.value = props.skipFocus
})
</script>

<template>
  <!-- LABEL BEHAVIOR -->
  <MoleculesLinkWrapper
    v-if="label && type !== 'anchor'"
    :anatomy="anatomy"
    :link-underline="linkUnderline"
    :underline-effect="underlineEffect"
    :tabindex="mountedSkipFocus ? '-1' : '0'"
    :to="url"
    :aria-label="ariaLabel"
    v-bind="$attrs"
    :class="[
      { 'focus-within:-outline-offset-1': outlineOffset },
      ctaContentStyle,
    ]"
    :target="type === 'blank' ? '_blank' : undefined"
  >
    <template #label>
      <UtilsMarkdown :content="label" container="span" />
    </template>
  </MoleculesLinkWrapper>
  <!-- CONTAINER BEHAVIOR -->
  <NuxtLink
    v-else
    :tabindex="mountedSkipFocus ? '-1' : '0'"
    :to="
      type === 'anchor'
        ? {
            hash: `#${target}`,
          }
        : url
    "
    :aria-label="ariaLabel"
    v-bind="$attrs"
    :class="[
      { 'focus-within:-outline-offset-1': outlineOffset },
      ctaContentStyle,
    ]"
    :target="type === 'blank' ? '_blank' : undefined"
  >
    <slot />
  </NuxtLink>
</template>
