<script setup lang="ts">
import type { UILinkProps } from './UILink.props'
import UILinkBase from './UILinkBase.vue'
import UILinkDialog from './UILinkDialog.vue'

const props = withDefaults(defineProps<UILinkProps>(), {
  anatomy: 'link',
  linkUnderline: true,
  underlineEffect: 'default',
})

const linkUnderline = ref(props.linkUnderline)

const ctaContentStyle = computed(() => {
  if (props.backgroundColor) {
    const isTransparent = props.backgroundColor === 'transparent'

    return isTransparent
      ? `!cta-s bg-${props.backgroundColor} text-editorial-${props.labelColor} border-transparent hover:!bg-transparent`
      : `!cta-s bg-neutral-${props.backgroundColor} text-editorial-${props.labelColor}`
  }

  return `text-editorial-${props.labelColor}`
})

watchEffect(() => {
  if (props.backgroundColor && props.backgroundColor !== 'transparent') {
    linkUnderline.value = false
  }
})
</script>

<template>
  <component
    :is="
      props.type === 'overlay' || props.type === 'sidesheet'
        ? UILinkDialog
        : UILinkBase
    "
    v-bind="{
      ...props,
      ctaContentStyle,
      linkUnderline,
      ...$attrs,
    }"
  >
    <slot />
  </component>
</template>
