<script lang="ts" setup>
import type { UILinkComponentProps } from './UILink.props'

withDefaults(defineProps<UILinkComponentProps>(), {
  anatomy: 'link',
  linkUnderline: true,
})
const { openDialog } = useDialog()
</script>

<template>
  <AtomsCta
    :anatomy="anatomy"
    :link-underline="linkUnderline"
    :aria-label="ariaLabel"
    :class="ctaContentStyle"
    v-bind="$attrs"
    @click="openDialog(target)"
  >
    <template #label>
      <slot />
      {{ label }}
    </template>
  </AtomsCta>
</template>
